import React from 'react';

const Conditions = props => {

    return (
        <>
            <section className="conditions">
                <div className="conditions-inner">
                    <div className="conditions-container">
                        <h1 className="large">Condiciones del Grupo</h1>
                        <div className="m-1"></div>
                        <p className="small">
                        Este es un grupo sin fines de lucro, las salidas se realizan de manera gratuita, al costo o a la
                        gorra. No comercializamos con estas salidas, dependemos de las propinas o colaboraciones de cada
                        uno de ustedes para afrontar los gastos del costo, los gastos del organizador por el tiempo
                        dedicado en el armado de cada evento; gastos de materiales para botiquín, compra de equipos
                        (handies, teléfono satelital, drone, cámara 360), permisos, entradas, nafta, micros, lanchas,
                        mulas, alfajores, calcos, etc. Y en los casos que existiera una diferencia, queda para el grupo
                        en concepto para cubrir fondo de emergencia, reposición de equipos que se rompen, que se
                        pierden, que se mojan, etc.
                    </p>
                    <p className="small">
                        Las colaboraciones o propinas pueden ser sugeridas dependiendo de la complejidad que tenga la
                        caminata.
                    </p>
                    <p className="small">
                        Cada persona al asistir cuenta con su seguro personal y manifiesta completo consentimiento al
                        modo de salida.
                    </p>
                    <p className="small">
                        Somos un grupo de amigos autosuficientes que fomentamos el compañerismo y solidaridad.
                    </p>
                    <p className="small">
                        Para los que no conocen la modalidad de nuestras salidas se realizan por burbujas
                        autogestionadas entre amigos, y amigos de amigos, compartiendo gastos. A los que les
                        entregaremos para cada salida handies a los fines de mantener la comunicación permanentemente.
                    </p>
                    <p className="small">
                        Dentro del grupo existen personas capacitadas para resolver cualquier tipo de situación, ellas
                        se encuentran abriendo y cerrando el grupo, y también de manera entremezclada (con equipos de
                        comunicación y botiquín). Mantenemos contacto permanente entre TODOS; y SIEMPRE consideramos un
                        plan alternativo o de Emergencia.
                    </p>
                    <p className="small">
                        La modalidad de llegar al punto de inicio y/o punto de encuentro, generalmente es
                        autogestionado, compartiendo gastos de micro o concurriendo por cuenta propia.
                    </p>
                    <p className="small">
                        Por favor si invitan amigos, deberán ser miembros del grupo Facebook “Trekking Buenos Aires” con
                        la debida antelación para que pueda conocer la modalidad y estilo del grupo.
                    </p>
                    <p className="small">
                        Muchos de los sitios que visitamos son agrestes o se encuentran en ruinas con algunas
                        particularidades que nos atrae para visitarlo y recorrerlo. Nos autoabastecemos la mayoría de
                        las veces. Acampamos o alquilamos cabañas, hostels y viajamos para lograr el objetivo
                        planificado en búsqueda de mejores precios.
                    </p>
                    <p className="small">
                        Es probable que no haya baños/duchas, o despensas hasta regresar a los vehículos. Como también,
                        en el recorrido nos encontremos con animales muertos, basura, espacios no tan agradables, que
                        crucemos alambrados (y que nadie se los levante o les ayude a cruzarlo), que se mojen los pies.
                        Pero al finalizar la travesía debería superar con creces la expectativa que conlleva toda
                        aventura, la satisfacción y experiencia de haber caminado y explorado lo que se propuso desde el
                        inicio!
                    </p>
                    <p className="small">
                        Todas las especificaciones se brindan en cada evento mediante el formulario de cada salida.
                    </p>
                    <p className="small">
                        Visitamos y recorremos márgenes de ríos, cerros, lugares en ruinas, bosques vírgenes, pueblos y
                        lugares con historia donde no asiste el turismo convencional.
                    </p>
                    <p className="small">
                        Tenemos un plan de acción para llegar a cumplir cada objetivo, pero eso no implica que no
                        acudamos a la IMPROVISACIÓN o cambiemos sobre la marcha según el terreno, circunstancia o
                        eventualidad. Siempre existen imponderables que obliguen a modificar lo planificado. Contamos
                        con Plan A, Plan B. Tenemos recursos y definimos modos para resolver.
                    </p>
                    <p className="small">
                        No se aceptan quejas por cansancio, por las irregularidades del terreno, por los insectos, por
                        la basura o por si alguien no les levantó el alambrado para cruzar o para llevar la mochila.
                        Obviamente fomentamos la SOLIDARIDAD, pero no implica que alguien se encuentre obligado a
                        realizarlo.
                    </p>
                    <p className="small">
                        Por favor no asistan a las salidas si buscan un Gran Líder, un coach, un personal trainer, un
                        sensei, un mentor, o alguien que esté incentivándolos durante todo el recorrido. No actuamos
                        como agencias de turismo las cuales los llevarán por senderos con todos los esquemas y
                        estructura apropiados para una salida organizada según los estándares. Nosotros no estamos
                        dentro de esos estándares.
                    </p>
                    <p className="small">
                        Si la salida es de modo exploratoria recuerden que nos arrojamos a la aventura sin temor a
                        traspasar cualquier obstáculo y/o impedimento del terreno por más áspero que sea, llámese
                        manglar, espinas, montaña, arroyos; de los que estarán al tanto de ante mano. Es esperable y
                        entendible, que estas dificultades incrementen el tiempo de recorrido.
                    </p>
                    <p className="small">
                        Si es una caminata de montaña requiere un tipo de logística diferente con otros gastos. Ya que
                        compramos comida específica, abonamos entradas o permisos, y/o proporcionamos propinas a algunos
                        prestadores de servicios con la intensión de mejorar los servicios.
                    </p>
                    <p className="small">
                        En salidas a otras provincias u otros países muchas veces estamos obligados como grupo a
                        contratar servicios de guías, micros, lanchas o aviones que por la cantidad de personas
                        conseguimos a un precio más económico.
                    </p>
                    <p className="small">
                        A veces los prestadores de servicios, micros, lanchas y/o aviones pueden demorarse, romperse y
                        eso escapa a nuestro control. De todos modos, se busca resolver el imprevisto. Cuando estas
                        situaciones ocurren entre todos lo superamos! No somos agencia de turismo. Somos un grupo de
                        amigos que afrontamos entre todos los eventuales imprevistos, predispuestos a pasarla bien, a
                        divertirnos en las buenas y en las malas, tener paciencia. Tolerancia y solidaridad. Según la
                        circunstancia o el hecho contamos con un Plan A, Plan B. Tenemos recursos y definimos modos para
                        resolver.
                    </p>
                    <p className="small">
                        Por favor si alguien no está de acuerdo, NO asistir.
                    </p>
                    <p className="small">
                        Los que acepten esta modalidad son BIENVENIDOS! Hacemos AVENTURAS! no paseos, no agencia, no
                        turistas.
                    </p>
                    <p className="small">
                        Autogestión, autonomía, solidaridad.
                    </p>
                    <p className="small">
                        &nbsp;
                    </p>

                    <p className="small">
                    <h3>ACLARACIÓN IMPORTANTE</h3>
                    </p>
                    <p className="small">
                        - Las salidas se postean en el muro con un formulario y un deslinde de responsabilidad que se
                        acepta, con esos datos y por cada salida se arma un grupo whatsapp en donde se dan
                        especificaciones y punto de encuentro y demás detalles. Cuando se acaba el cupo la salida se
                        elimina del muro.
                    </p>
                    <p className="small">
                        - Los trekkings comerciales nada tienen que ver con nosotros. NO se asemejan a nuestro estilo,
                        ni en su costo, ni en su concepción, ni en su forma. No realizamos salidas estereotipadas del
                        estilo “Turista”.
                    </p>
                    <p className="small">
                        -Es probable que existan miles de incongruencias con una agencia de turismo, nosotros NO LO
                        SOMOS. Y no tenemos la obligatoriedad de estar matriculados como Guías.
                    </p>
                    <p className="small">
                        -Puede ser que la persona voluntaria para brindar una charla de historia, no sea historiador, o
                        el vaqueano no tenga estudios terciarios.
                    </p>
                    <p className="small">
                        &nbsp;
                    </p>
                    <p className="small">
                    <h3>COMUNICADO:</h3>
                    </p>
                    <p className="small">
                        Si alguno está pasando un momento difícil, y no puede colaborar, puede participar comunicándonos
                        por privado a cualquier salida sin tener que colaborar monetariamente. Incluso sin abonar la
                        reserva, solamente presentando todos los datos y el apto médico (en caso de salidas de nivel
                        intermedios).
                    </p>
                    <p className="small">
                        Para aquellos que pueden colaborar:
                    </p>
                    <p className="small">
                        La reserva se hace completando un formulario con todos los datos que son validados con un
                        depósito mínimo al realizar la inscripción.<br />
                        De esta manera nos aseguramos que la persona que va a asistir es una persona real, con nombre y
                        apellido, que tiene cobertura médica y que además tiene datos reales. A la hora de ofrecer
                        lugares en autos o compartir viaje con otras personas, cada uno de los que están en el grupo
                        WhatsApp de cada salida, está debidamente identificado para seguridad de todos.
                    </p>
                    <p className="small">
                        El tiempo dedicado al armado de cada salida, y los costos que incurren, además de lo que
                        juntamos para equipos y armado de salidas más complejas a un costo menor, hacen que contemos con
                        las colaboraciones para seguir haciendo esta actividad posible.
                    </p>
                    <p className="small">
                        Si alguno ve que la vida no le sonríe, y no llega o no puede colaborar, o le parece demasiado,
                        saben que SIEMPRE van a poder venir GRATIS, y seguir participando, pero deben hablarlo en
                        privado por cada salida.
                    </p>
                    <p className="small">
                        Si la vida les sonríe, y quieren dejar colaboración, y pueden, y además les parece gratificante
                        nuestro trabajo, pues bueno, siempre aceptamos propinas y colaboraciones para seguir sumando en
                        todos los aspectos. Estas, ahora tienen un mínimo según la salida a realizar. Por ello, se
                        informará mediante el formulario: el monto sugerido de colaboración, dependiendo de la
                        complejidad y los permisos que sean necesarios solicitar, la cantidad de colaboradores, gastos
                        previos, gastos de salidas solidarias, gastos de equipos, etc.
                    </p>
                    <p className="small">
                        Por eso, si alguno cree que puede colaborar y quiere, y no lo ve como una carga, y además se
                        siente feliz de caminar junto a nosotros puede colaborar.
                    </p>
                    <p className="small">
                        Y si no puede, y no encuentra un grupo de contención para estas salidas, pues siempre estaremos
                        aquí para recibirlos con los brazos abiertos e incluso ayudarlos a que puedan asistir
                        otorgándoles algún liberado, y que sigan participando hasta que se repongan y la rueda de la
                        vida eleve nuevamente!!!!
                    </p>
                    <p className="small">
                        Si alguno se siente ofendido/a por esto y/o tienen sugerencias, por favor escriban al WhatsApp
                        1132364469 y veremos el modo de solucionarlo, y que puedan seguir asistiendo!!!
                    </p>
                    <p className="small">
                        Nuestra intención siempre es buscar nuevos lugares, ir variando recorridos, explorar y disfrutar
                        de la naturaleza, con un estilo y una forma diferente al comercial. Mucho más autónomo por parte
                        de los participantes, mucho más independiente y económico, y también mucho más libre, en lugares
                        con menos senderos, menos carteles, menos protocolos, y menos esquemas turísticos y de comercio.
                    </p>
                    <p className="small">
                        Hemos tomado en cuenta cada una de las sugerencias de los asistentes y también nos gustaría
                        seguir leyéndolos!!!
                    </p>
                    <p className="small">
                        El modo de manejarnos y las reglas adoptadas surjen del devenir en la experiencia de salida tras
                        salida a través de estos años.
                    </p>
                    <p className="small">
                        Al asistir se aceptan las condiciones del grupo mediante una declaración jurada que posteo a
                        continuación. Por cada salida se informa detalladamente la intensidad y la modalidad de la
                        actividad a realizar para contar con pleno conocimiento del escenario y de la caminata a
                        realizar.
                    </p>
                    <p className="small">
                        ----------------------------------------------------------------------------------------------------------
                    </p>
                    <p className="small">
                    <h3>DECLARACION JURADA DE SALUD.</h3>
                    </p>
                    <p className="small">
                        Por intermedio de la presente declaro bajo juramento -y en forma libre y espontánea- que tengo
                        pleno conocimiento de la actividad a desarrollar porque he sido informado con anterioridad en
                        forma minuciosa y detallada.
                    </p>
                    <p className="small">
                        En virtud de ello, declaro encontrarme físicamente apto y con el entrenamiento que requiere la
                        actividad a realizar, dejándose expresamente establecido que ninguna responsabilidad tendrá el
                        coordinador de la actividad por cualquier perjuicio que pudiera sufrir con motivo de mi
                        participación.
                    </p>
                    <p className="small">
                        Manifiesto haberme efectuado los estudios médicos necesarios a tal fin y contar con apto físico
                        vigente para la realización de la actividad. A tal fin declaro asimismo bajo juramento que no
                        padezco afecciones físicas adquiridas o congénitas, ni lesiones que pudieran ocasionar
                        trastornos en mi salud o condiciones de vida, como consecuencia de participar en la presente
                        actividad.
                    </p>
                    <p className="small">
                        ASUMO, ATENTO LAS CARACTERISTICAS DE LA ACTIVIDAD EN LA QUE VOLUNTARIAMENTE PARTICIPARE, QUE
                        INCLUYE MAS DE UNA DISCIPLINA DEPORTIVA, QUE HE EVALUADO JUNTO A MI MEDICO TRATANTE Y QUIEN ME
                        HA INFORMADO QUE NO PADEZCO DE TRASTORNO ALGUNO QUE IMPIDA MI PARTICIPACION EN LA ACTIVIDAD. POR
                        ELLO, HE DECIDIDO POR MI PROPIA Y EXCLUSIVA VOLUNTAD PARTICIPAR EN ESTA ACTIVIDAD, LIBERANDO DE
                        TODA RESPONSABILIDAD DEL TIPO QUE FUERA AL COORDINADOR. Declaro asimismo contar con cobertura
                        médica, entregando al coordinador una copia de mi carnet de afiliado.
                    </p>
                    <p className="small">
                    <h3>DESLINDE DE RESPONSABILIDAD.</h3>
                    </p>
                    <p className="small">
                        Por la presente declaro que participo de forma voluntaria en la actividad y que tengo pleno
                        conocimiento de que la participación en la misma implica afrontar los riesgos físicos de un
                        deporte intenso. Asumo voluntariamente total responsabilidad del riesgo y lo que pueda suceder
                        tanto a mi persona como a terceros por mi actuación. Declaro haber realizado los entrenamientos
                        físicos y técnicos previos y necesarios para la práctica de esta disciplina deportiva y estar en
                        perfectas condiciones psico‐físicas para participar en la actividad, dados los reconocimientos
                        médicos a que he sido sometido recientemente, gozando de plena salud y no tener ningún
                        impedimento físico o deficiencia que pudiera provocarme lesiones u otro daño corporal como
                        consecuencia de mi participación. Asimismo, declaro que participo con la indumentaria adecuada,
                        que me comprometo a estudiar el mapa del circuito en cuanto lo publiquen y del lugar de
                        emplazamiento del mismo. Desligo de total responsabilidad al organizador y/o coordinador de
                        cualquier accidente que me ocasionare lesiones que afecten la capacidad física, intelectual,
                        laboral, deportiva y fisiológica, psicológica, u otra en general, en forma total o parcial,
                        transitoria o permanente, y muerte durante el desarrollo de la actividad o en consecuencia de la
                        misma, tanto en lo que hace a reclamos por daños y perjuicios, lucro cesante, daño moral propio
                        o de los derecho habientes.<br />
                        Asimismo, deslindo al organizador y/o coordinador de toda responsabilidad por los riesgos
                        asociados con la participación en la actividad, esto es caídas, contacto con otros
                        participantes, consecuencias del clima, tránsito vehicular, condiciones del camino y/o cualquier
                        otra clase de riesgo que se pudiera ocasionar, así como de cualquier extravío, robo y/o hurto
                        que pudiera sufrir EN MIS PERTENENCIAS. Como así también manifiesto que no serán responsables
                        por incendios, cortocircuitos, robos, hurtos, caso fortuito, cualquiera fuera la causa que lo
                        origine, daño en mi salud provenientes de riñas o peleas de terceros, daño en mi salud
                        proveniente de afecciones físicas o no, que puedan acontecer con anterioridad, durante el
                        transcurso o con posterioridad a la finalización de la actividad.
                        <br />
                        Me abstendré de arrojar residuos a la vía pública durante todo el recorrido.
                        <br />
                        Entiendo que podrá suspenderse o prorrogarse fechas, incluso incrementar o reducir la cantidad
                        de inscripciones en función de las necesidades o la disponibilidad técnica o estructural.
                        <br />
                        Autorizo el uso de fotografías, películas, videos, grabaciones y cualquier otro medio de
                        registro de esta actividad para cualquier uso legítimo, sin compensación económica alguna.
                    </p>
                    <p className="small">
                        ----------------------------------------------------------------------------------------------------------
                    </p>
                    <p className="small">
                        En las salidas de nivel intermedios o avanzados mantendré mi posición durante el recorrido entre
                        el primero y el último y en caso de salirme lo hago bajo mi total responsabilidad y conciencia
                        de adulto avisando por Handy o por cualquier medio de comunicación certero.
                        <br />
                        Me comprometo a ayudar a otros participantes en caso de inconvenientes y en caso de quedar
                        rezagado, con falta de agua o la situación que sea, avisaré por handy o personalmente la
                        urgencia PARA ASISTENCIA INMEDIATA Y DESPLIEGUE DE EMERGENCIA.
                        <br />
                        Asumo que colaboraré en el espíritu de este evento por tratarse de una caminata recreativa sin
                        espíritu competitivo ni de velocidad.<br />
                        Aceptaré cualquier indicación y/o recomendación del administrador o coordinador/es relativa a mi
                        capacidad y condición psicofísica de completar con seguridad y bienestar el recorrido, incluso
                        su interrupción inmediata.
                    </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Conditions;
